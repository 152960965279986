import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/SEO"
import BlogPostList from "../components/blogPostList"
import styles from "./blogTemplate.module.scss"
import Carousel from "nuka-carousel"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

let slideToShowNumber = 3
if (typeof window !== `undefined`) {
  if (window.innerWidth < 768) slideToShowNumber = 1
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div>
      <SEO postData={markdownRemark} pageImage={frontmatter.photo ? frontmatter.photo.childImageSharp.fluid.src : null}/>
      <Header />
      <div className={styles.block}>
        <Row>
          <Col md={8}>
            <div className={styles.content}>
              <h1>{frontmatter.title}</h1>
              <div              
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            {frontmatter.gallery && frontmatter.gallery.length > 0 && (
              <Carousel
                slidesToShow={slideToShowNumber}
                withoutControls={false}
                cellSpacing={20}
                wrapAround={true}
                renderBottomCenterControls={false}
              >
                {frontmatter.gallery.map(({ image }) => (
                  <Img fluid={image.childImageSharp.fluid} />
                ))}
              </Carousel>
            )}
          </Col>
          <Col md={4}>
            <BlogPostList />
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 150)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        photo {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          alt
          title
          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
