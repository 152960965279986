import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styles from "./blogPostList.module.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query allBlogPostsQuery {
        allMarkdownRemark(filter: { fields: { contentType: { eq: "blog" } } }, sort: {fields: frontmatter___date, order: DESC}) {
          totalCount
          edges {
            node {
              id
              html
              excerpt(pruneLength: 420)
              fields {
                slug
                contentType
              }
              frontmatter {
                description
                title
                path
                date(formatString: "MMMM YYYY")
                photo {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.container}>
        <h3>Recent Posts</h3>
        <ul>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <li>
            <Link to={node.frontmatter.path}>{node.frontmatter.title}</Link>
            <div className={styles.small}>{node.frontmatter.date}</div>
          </li>      
        ))}
      </ul>
      </div>
      
    )}
  />
)